import { useFormat } from 'helpers/hooks/useFormat';

interface Props {
  type: 'cardError' | 'errorCreatingAccount';
}

const ErrorFeedback = ({ type }: Props) => {
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  let message = '';
  if (type === 'cardError')
    message = formatErrorMessage({
      id: 'form.cardInvalid',
      defaultMessage: 'Provided data is incorrect, please check and try again later.',
    });
  if (type === 'errorCreatingAccount')
    message = formatErrorMessage({
      id: 'form.accountError',
      defaultMessage: 'Sorry, we could not create your account.',
    });

  return (
    <div className="mt-24 text-red-500">
      <p className="font-bold">{message}</p>
    </div>
  );
};

export default ErrorFeedback;
