import { useEffect, useState } from 'react';
import Container from 'components/commercetools-ui/atoms/container';
import ThankYouBanner from 'components/commercetools-ui/atoms/thank-you-banner';
import Steps from './steps';
import { Data, Form, ActivationContent } from './types';

export interface ActivationProps {
  studio: ActivationContent;
}

const Activation: React.FC<ActivationProps> = ({ studio }) => {
  const [data, setData] = useState({} as Data);
  const [complete, setComplete] = useState(false);
  const [isError, setIsError] = useState(false);

  // Enable child components to manipulate form data
  const form: Form = {
    completeState: {
      value: complete,
      set: setComplete,
    },
    dataState: {
      value: data,
      set: setData,
    },
    submitError: {
      value: isError,
      set: setIsError,
    },
  };

  useEffect(() => {
    if (complete) window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [complete]);

  return (
    <div className="bg-background py-32 lg:py-40">
      <Container>
        {!complete && <Steps form={form} studio={studio} />}

        {complete && (
          <ThankYouBanner
            thankYouScreenTitle={studio.thankYouScreenTitle}
            thankYouScreenSubtitle={studio.thankYouScreenSubtitle}
            thankYouScreenDescription={studio.thankYouScreenDescription}
            thankYouScreenCtaButtonLabel={studio.thankYouScreenCtaButtonLabel}
            thankYouScreenCtaButtonUrl={studio.thankYouScreenCtaButtonUrl}
            additionalClassName="mx-20 py-32 lg:mx-46"
          />
        )}
      </Container>
    </div>
  );
};

export default Activation;
