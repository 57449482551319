import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import Button from 'components/commercetools-ui/atoms/button';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import Input from 'components/commercetools-ui/atoms/input';
import InputPassword from 'components/commercetools-ui/atoms/input-password';
import Link from 'components/commercetools-ui/atoms/link';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import AsteriskIcon from 'components/icons/AsteriskIcon';
import CheckmarkIcon from 'components/icons/CheckmarkIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import useYupValidation from 'helpers/hooks/useYupValidation';
import ErrorFeedback from './errorFeedback';
import { Step2, Data, Step, CheckboxEvent } from './types';

const Step2 = ({ form, studio, onClickSubmit }: Step) => {
  const s = studio as Step2;
  const formData = form.dataState.value as Data;

  const [isValid, setValid] = useState(false);

  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });

  const requiredError = formatErrorMessage({ id: 'form.required', defaultMessage: 'The field is required' });
  const emailError = formatErrorMessage({ id: 'form.email', defaultMessage: 'Email is invalid' });
  const passwordError = formatErrorMessage({
    id: 'form.password',
    defaultMessage:
      'Password must contain between 8 and 64 characters and  must contain at least 3 of the following elements: lowercase letter, capital letter, digit, special character',
  });
  const passwordConfirmationError = formatErrorMessage({
    id: 'form.passwordConfirmation',
    defaultMessage: 'Passwords do not match',
  });
  const termsConditionsError = formatErrorMessage({
    id: 'form.termsConditions',
    defaultMessage: 'You must accept the terms and conditions',
  });

  const { fieldValidation, formValidation } = useYupValidation();

  const schema = useMemo(() => {
    return yup.object().shape({
      email: yup.string().required(requiredError).email(emailError),
      password: yup
        .string()
        .required(requiredError)
        .matches(
          /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&\/=?_.,:;\\-])|(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&\/=?_.,:;\\-])|(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&\/=?_.,:;\\-])).{8,64}$/,
          passwordError,
        ),
      passwordConfirmation: yup
        .string()
        .required(requiredError)
        .oneOf([yup.ref('password'), null], passwordConfirmationError),
      termsConditionsSelgrosAccepted: yup.boolean().required(requiredError).oneOf([true], termsConditionsError),
      termsConditionsTransgourmetAccepted: yup.boolean().required(requiredError).oneOf([true], termsConditionsError),
    });
  }, [requiredError, emailError, passwordError, passwordConfirmationError, termsConditionsError]);

  const validateEmail = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'email', value);
    },
    [fieldValidation, schema, formData],
  );

  const validatePassword = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'password', value);
    },
    [fieldValidation, schema, formData],
  );

  const validatePasswordConfirmation = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'passwordConfirmation', value);
    },
    [fieldValidation, schema, formData],
  );

  useEffect(() => {
    setValid(formValidation(schema, formData));
  }, [formData, formValidation, schema]);

  const handleDataChange = (e: React.ChangeEvent<HTMLInputElement> | CheckboxEvent) => {
    if (e.hasOwnProperty('checked')) {
      // Checkbox field event
      const ev = e as CheckboxEvent;
      const newData = { ...form.dataState.value, [ev.name]: ev.checked };
      form.dataState.set(newData);
    } else {
      // Input field event
      const ev = e as React.ChangeEvent<HTMLInputElement>;
      const newData = { ...form.dataState.value, [ev.target.name]: ev.target.value };
      form.dataState.set(newData);
    }
  };

  const passwordTips = [
    s.passwordTipsBoxTip1Description,
    s.passwordTipsBoxTip2Description,
    s.passwordTipsBoxTip3Description,
    s.passwordTipsBoxTip4Description,
  ];

  const declarations = [
    {
      checkboxName: 'termsConditionsTransgourmetAccepted',
      text: s.termsAndConditionsTransgourmet[0].termsAndConditionsTransgourmetDescription,
      links: [
        {
          label: s.termsAndConditionsTransgourmet[0].termsAndConditionsTransgourmetLink1Label,
          url: s.termsAndConditionsTransgourmet[0].termsAndConditionsTransgourmetLink1Url,
        },
        {
          label: s.termsAndConditionsTransgourmet[0].termsAndConditionsTransgourmetLink2Label,
          url: s.termsAndConditionsTransgourmet[0].termsAndConditionsTransgourmetLink2Url,
        },
      ],
    },
    {
      checkboxName: 'termsConditionsSelgrosAccepted',
      text: s.termsAndConditionsSelgros[0].termsAndConditionsSelgrosDescription,
      links: [
        {
          label: s.termsAndConditionsSelgros[0].termsAndConditionsSelgrosLink1Label,
          url: s.termsAndConditionsSelgros[0].termsAndConditionsSelgrosLink1Url,
        },
        // {
        //   label: s.termsAndConditionsSelgros[0].termsAndConditionsSelgrosLink2Label,
        //   url: s.termsAndConditionsSelgros[0].termsAndConditionsSelgrosLink2Url,
        // },
      ],
    },
  ];

  const communicationConsents = [
    {
      checkboxName: 'emailMarketingAccepted',
      text: s.commercialCommunicationSectionEmailDescription,
    },
    {
      checkboxName: 'smsMarketingAccepted',
      text: s.commercialCommunicationSectionSmsDescription,
    },
  ];

  return (
    <div>
      <div>
        <p className="text-18 font-bold">{s.introSectionTitle}</p>
        <p className="mt-10">{s.introSectionDescription}</p>
      </div>

      <div className="mt-24 flex flex-col gap-24">
        <Input
          name="email"
          type="text"
          label={s.emailFieldLabel}
          required
          value={(formData['email'] as Data['email']) ?? ''}
          disabled
          validationSchema={validateEmail}
        />
        <InputPassword
          name="password"
          label={s.passwordFieldLabel}
          required
          value={(formData['password'] as Data['password']) ?? ''}
          onChange={handleDataChange}
          validationSchema={validatePassword}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
        />
        <InputPassword
          name="passwordConfirmation"
          label={s.passwordConfirmationFieldLabel}
          required
          value={(formData['passwordConfirmation'] as Data['passwordConfirmation']) ?? ''}
          onChange={handleDataChange}
          validationSchema={validatePasswordConfirmation}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
        />
        <div className="bg-base-accent-3 p-24">
          {passwordTips.map((text, i) => {
            return (
              <div key={i} className="mt-12 flex items-center gap-12 first:mt-0">
                <span className="h-24 w-24 rounded-full bg-base-accent-1 text-neutral-5">
                  <CheckmarkIcon />
                </span>
                <p>{text}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="mt-32 flex flex-col gap-24 border-t border-t-neutral-2 pt-32 lg:gap-32">
        <div className="font-bold">
          <p className="text-18">{s.marketingConsentsSectionTitle}</p>
          <p className="mt-10">{s.marketingConsentsSectionDescription}</p>
        </div>

        <div className="bg-base-accent-3 p-20">
          {declarations.map(({ text, links, checkboxName }, i) => {
            return (
              <div key={i} className="mt-20 first:mt-0">
                <label className="mt-12 flex items-start gap-12 first:mt-0">
                  <Checkbox
                    containerClassName="mt-2"
                    name={checkboxName}
                    checked={(formData[checkboxName as keyof Data] as boolean) ?? ''}
                    onChange={handleDataChange}
                  />
                  <span>
                    {text}{' '}
                    <span className="text-neutral-3 [&>svg]:-mt-12 [&>svg]:inline">
                      <AsteriskIcon scale={0.4} />
                    </span>
                  </span>
                </label>
                {links.length > 0 && (
                  <div className="mt-12">
                    {links.map(({ label, url }, i) => {
                      return (
                        <div key={i} className="ml-32 mt-8 first:mt-0">
                          <Link link={url} className="inline-flex gap-4">
                            <span className="shrink-0">
                              <ArrowRightIcon />
                            </span>
                            <span>{label}</span>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {/* <label className="flex items-start gap-12">
          <Checkbox
            containerClassName="mt-2"
            name="dataProcessingAccepted"
            checked={(formData['dataProcessingAccepted'] as Data['dataProcessingAccepted']) ?? ''}
            onChange={handleDataChange}
          />
          <p>{s.personalDataConsentDescription}</p>
        </label> */}

        <div className="flex flex-col gap-24">
          <p className="font-bold">{s.commercialCommunicationSectionTitle}</p>

          {communicationConsents.map(({ text, checkboxName }, i) => {
            return (
              <label key={i} className="mt-12 flex items-start gap-12 first:mt-0">
                <Checkbox
                  containerClassName="mt-2"
                  name={checkboxName}
                  checked={(formData[checkboxName as keyof Data] as boolean) ?? ''}
                  onChange={handleDataChange}
                />
                <p>{text}</p>
              </label>
            );
          })}
        </div>
      </div>

      <div className="mt-32 h-1 border-t border-t-neutral-2"></div>

      <Button onClick={() => onClickSubmit()} className="mt-32 max-sm:w-full" disabled={!isValid}>
        {s.ctaButtonLabel}
      </Button>

      {form.submitError.value && <ErrorFeedback type="errorCreatingAccount" />}
    </div>
  );
};

export default Step2;
