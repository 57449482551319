'use client';

import React from 'react';
import Activation from 'components/commercetools-ui/organisms/authentication/activation/';
import { ActivationContent } from 'components/commercetools-ui/organisms/authentication/activation/types';
import { TasticProps } from 'frontastic/tastics/types';

const ActivationTastic = ({ data }: TasticProps<ActivationContent>) => {
  return <Activation studio={data} />;
};

export default ActivationTastic;
