import { useCallback, useEffect, useMemo, useState } from 'react';
import { ActivationValidation } from 'shared/types/account/ActivationValidation';
import * as yup from 'yup';
import Button from 'components/commercetools-ui/atoms/button';
import Input from 'components/commercetools-ui/atoms/input';
import CheckmarkIcon from 'components/icons/CheckmarkIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import useYupValidation from 'helpers/hooks/useYupValidation';
import { sdk } from 'sdk';
import Image from 'frontastic/lib/image';
import ErrorFeedback from './errorFeedback';
import { Step1, Data, Step } from './types';

const Step1 = ({ form, studio, onClickSubmit }: Step) => {
  const s = studio as Step1;
  const formData = form.dataState.value as Data;

  const [isValid, setValid] = useState(false);
  const [validatingActivation, setValidatingActivation] = useState(false);
  const [activationError, setActivationError] = useState(false);

  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });

  const requiredError = formatErrorMessage({ id: 'form.required', defaultMessage: 'The field is required' });
  const emailError = formatErrorMessage({ id: 'form.email', defaultMessage: 'Email is invalid' });
  const cardNumberError = formatErrorMessage({
    id: 'form.cardNumber',
    defaultMessage: 'The card number consists of 10 digits',
  });

  const { fieldValidation, formValidation } = useYupValidation();

  const schema = useMemo(() => {
    return yup.object().shape({
      cardNumber: yup
        .string()
        .required(requiredError)
        .matches(/^\d{10}?$/, cardNumberError),
      firstName: yup.string().trim().required(requiredError),
      lastName: yup.string().trim().required(requiredError),
      email: yup.string().required(requiredError).email(emailError),
    });
  }, [requiredError, cardNumberError, emailError]);

  const validateCardNumber = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'cardNumber', value);
    },
    [fieldValidation, schema, formData],
  );

  const validateFirstName = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'firstName', value);
    },
    [fieldValidation, schema, formData],
  );

  const validateLastName = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'lastName', value);
    },
    [fieldValidation, schema, formData],
  );

  const validateEmail = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'email', value);
    },
    [fieldValidation, schema, formData],
  );

  useEffect(() => {
    setValid(formValidation(schema, formData));
  }, [formData, formValidation, schema]);

  const handleDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newData = { ...form.dataState.value, [e.target.name]: e.target.value };
    form.dataState.set(newData);
  };

  const handleActivationValidation = async () => {
    setValidatingActivation(true);

    try {
      const response = await sdk.callAction<ActivationValidation>({
        actionName: 'account/validateCard',
        payload: form.dataState.value,
      });

      setActivationError(response.isError);
      if (!response.isError) {
        form.dataState.set({ ...form.dataState.value, ...response.data });
        onClickSubmit();
      }
    } catch (error) {
      setActivationError(true);
    }

    setValidatingActivation(false);
  };

  const SelgrosCard = () => (
    <div className="bg-base-accent-3 p-24 lg:p-20">
      <div className="flex flex-col justify-between gap-20 lg:flex-row">
        <div className="flex items-center gap-8 lg:gap-12">
          <span className="h-24 w-24 rounded-full bg-base-accent-1 text-neutral-5">
            <CheckmarkIcon />
          </span>
          <p>{s.informationSectionDescription}</p>
        </div>
        <div className="relative h-90 w-auto self-center lg:h-60 lg:w-185">
          <Image {...s.informationSectionImage} priority loading="eager" style={{ objectFit: 'contain' }} />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex flex-col gap-32 lg:gap-24">
        <div>
          <p className="text-18 font-bold">{s.introSectionTitle}</p>
          <p className="mt-10">{s.introSectionDescription}</p>
        </div>

        <div className="visible max-lg:hidden">
          <SelgrosCard />
        </div>

        <div className="flex flex-col gap-20">
          <div>
            <Input
              name="cardNumber"
              type="text"
              label={s.cardNumberFieldLabel}
              required
              value={(formData['cardNumber'] as Data['cardNumber']) ?? ''}
              onChange={handleDataChange}
              validationSchema={validateCardNumber}
            />
          </div>
          <div className="flex gap-24 max-lg:flex-col lg:gap-12">
            <Input
              name="firstName"
              type="text"
              label={s.firstNameFieldLabel}
              required
              value={(formData['firstName'] as Data['firstName']) ?? ''}
              onChange={handleDataChange}
              wrapperClassName="lg:w-1/2"
              validationSchema={validateFirstName}
            />
            <Input
              name="lastName"
              type="text"
              label={s.lastNameFieldLabel}
              required
              value={(formData['lastName'] as Data['lastName']) ?? ''}
              onChange={handleDataChange}
              wrapperClassName="lg:w-1/2"
              validationSchema={validateLastName}
            />
          </div>
          <div>
            <Input
              name="email"
              type="text"
              label={s.emailFieldLabel}
              required
              value={(formData['email'] as Data['email']) ?? ''}
              onChange={handleDataChange}
              validationSchema={validateEmail}
            />
          </div>
        </div>

        <div className="visible lg:hidden">
          <SelgrosCard />
        </div>
      </div>

      <Button
        onClick={handleActivationValidation}
        className="mt-32 max-sm:w-full"
        disabled={!isValid}
        loading={validatingActivation}
      >
        {s.ctaButtonLabel}
      </Button>

      {activationError && <ErrorFeedback type="cardError" />}
    </div>
  );
};

export default Step1;
