import Link from 'components/commercetools-ui/atoms/link';
import ThumbsUpIcon from 'components/icons/ThumbsUpIcon';
import { Reference } from 'types/reference';

export interface ThankYouBannerProps {
  thankYouScreenTitle: string;
  thankYouScreenSubtitle?: string;
  thankYouScreenDescription?: string;
  thankYouScreenCtaButtonLabel: string;
  thankYouScreenCtaButtonUrl: string | Reference;
  additionalClassName?: string;
}

const ThankYouBanner: React.FC<ThankYouBannerProps> = ({
  thankYouScreenTitle,
  thankYouScreenSubtitle,
  thankYouScreenDescription,
  thankYouScreenCtaButtonLabel,
  thankYouScreenCtaButtonUrl,
  additionalClassName = '',
}) => {
  return (
    <div className={`${additionalClassName} flex h-full flex-col items-center justify-center bg-base-accent-3 `}>
      <span className="text-base-accent-1">
        <ThumbsUpIcon scale={3.5} />
      </span>
      <p className="text-center text-40 font-bold text-neutral-4">{thankYouScreenTitle}</p>
      {thankYouScreenSubtitle && (
        <p className="text-center text-18 text-neutral-4 lg:text-20">{thankYouScreenSubtitle}</p>
      )}
      {thankYouScreenDescription && (
        <p className="mt-40 text-18 text-neutral-4 lg:text-20">{thankYouScreenDescription}</p>
      )}
      <Link link={thankYouScreenCtaButtonUrl} variant="button-primary" className="mt-20 lg:mt-32">
        {thankYouScreenCtaButtonLabel}
      </Link>
    </div>
  );
};

export default ThankYouBanner;
