import React, { useState } from 'react';
import { Account } from 'shared/types/account';
import { sdk } from 'sdk';
import Step from './step';
import Step1 from './step1';
import Step2 from './step2';
import { Form, ActivationContent } from './types';

interface Props {
  form: Form;
  studio: ActivationContent;
}

const Steps = ({ form, studio }: Props) => {
  const [current, setCurrent] = useState(0);
  const goToNextStep = () => setCurrent(current + 1);
  const onEdit = (index: number) => setCurrent(index);

  const onSubmit = async () => {
    try {
      const response = await sdk.callAction<Account>({
        actionName: 'account/activateAccount',
        payload: form.dataState.value,
      });

      form.submitError.set(response.isError);
      if (!response.isError) {
        form.completeState.set(true);
      }
    } catch (error) {
      form.submitError.set(true);
    }
  };

  const stepProps = {
    step1: {
      form,
      studio: studio.step1[0],
      onClickSubmit: goToNextStep,
    },
    step2: {
      form,
      studio: studio.step2[0],
      onClickSubmit: onSubmit,
    },
  };

  const steps = () => {
    return [
      {
        label: studio.step1[0].headerTitle,
        Component: <Step1 {...stepProps.step1} />,
      },
      {
        label: studio.step2[0].headerTitle,
        Component: <Step2 {...stepProps.step2} />,
      },
    ];
  };

  return (
    <div className="mx-20 lg:mx-46">
      {steps().map(({ Component, label }, index) => (
        <Step
          key={index}
          label={label}
          number={index + 1}
          isExpanded={index === current}
          isCompleted={index < current}
          onEdit={() => onEdit(index)}
          Component={Component}
        />
      ))}
    </div>
  );
};

export default Steps;
